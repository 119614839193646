package io.lomito.webpage.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextDecorationLine
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaBars
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import io.lomito.webpage.models.Section
import io.lomito.webpage.models.Theme
import io.lomito.webpage.styles.LogoStyle
import io.lomito.webpage.styles.NavigationItemStyle
import io.lomito.webpage.util.Constants.FONT_FAMILY
import io.lomito.webpage.util.Constants.HEADER_WIDTH
import io.lomito.webpage.util.Res.Image.LOGO
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun Header(
    onMenuClicked: () -> Unit
) {

    val breakpoint = rememberBreakpoint()

    val headerWidth = if (breakpoint > Breakpoint.MD) {

        HEADER_WIDTH.percent
    } else {
        90.percent
    }

    Row(
        modifier = Modifier
            .fillMaxWidth(headerWidth)
            .margin(topBottom = 50.px),
        horizontalArrangement = Arrangement.SpaceBetween,
        verticalAlignment = Alignment.CenterVertically
    ) {

        LeftSide(
            breakpoint,
            onMenuClicked
        )

        if (breakpoint > Breakpoint.MD) {

            RightSide()
        }
    }
}

@Composable
fun LeftSide(
    breakpoint: Breakpoint,
    onMenuClicked: () -> Unit
) {

    Row(
        verticalAlignment = Alignment.CenterVertically
    ) {

        if (breakpoint <= Breakpoint.MD) {

            FaBars(
                modifier = Modifier
                    .margin(right = 15.px)
                    .onClick {

                       onMenuClicked()
                    },
                size = IconSize.XL,
            )
        }

        Image(
            modifier = LogoStyle.toModifier().size(80.px),
            src = LOGO,
            desc = "Lomito logo"
        )
    }
}

@Composable
fun RightSide() {

    Row(
        modifier = Modifier
            .fillMaxWidth()
            .borderRadius(r = 50.px)
            .backgroundColor(Theme.LighterGray.rgb)
            .padding(all = 20.px),
        horizontalArrangement = Arrangement.End,
    ) {

        Section.values().take(6).forEach {  section: Section ->

            Link(
                modifier = NavigationItemStyle.toModifier()
                    .padding(right = 30.px)
                    .fontFamily(FONT_FAMILY)
                    .fontSize(18.px)
                    .fontWeight(FontWeight.Normal)
                    .textDecorationLine(TextDecorationLine.None),
                path = section.path,
                text = section.title
            )
        }
    }
}
package io.lomito.webpage.util

object Constants {

    const val WEBSITE = "https://lomito.io/"

    const val SECTION_WIDTH = 1920
    const val HEADER_WIDTH = 80

    const val FONT_FAMILY = "Montserrat"

    const val LOREM_IPSUM_LONG = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
    const val LOREM_IPSUM_SHORTEST = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
    const val LOREM_IPSUM_SHORT = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
}

object Res {

    object Icon {

        const val web = "web.png"
        const val seo = "seo.png"
        const val design = "design.png"
        const val business = "business.png"
        const val apple = "apple.png"
        const val android = "android.png"
        const val link = "link_icon.svg"
        const val user = "user_icon.svg"
        const val shield = "shield_icon.svg"
        const val happy = "happy_icon.svg"
        const val checkmark = "checkmark_icon.svg"
        const val star = "star_icon.svg"
    }

    object Image {

        const val BACKGROUND = "background.svg"
        const val LOGO = "logo.svg"

        const val portfolio1 = "portfolio1.png"
        const val portfolio2 = "portfolio2.png"
        const val portfolio3 = "portfolio3.png"
        const val portfolio4 = "portfolio4.jpg"
        const val portfolio5 = "portfolio5.png"

        const val avatar1 = "avatar1.png"
        const val avatar2 = "avatar2.png"
        const val avatar3 = "avatar3.png"
        const val avatar4 = "avatar4.png"
        const val avatar5 = "avatar5.png"
        const val avatar6 = "avatar6.png"
    }
}
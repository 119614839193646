package io.lomito.webpage.models

enum class Section(
    val id: String,
    val title: String,
    val subtitle: String,
    val path: String
) {

    Home(
        id = "home",
        title = "Inicio",
        subtitle = "",
        path = "#home"
    ),
    About(
        id = "about",
        title = "Aplicación móvil",
        subtitle = "¡Descarga nuesta app!",
        path = "#about"
    ),
    /*Service(
        id = "service",
        title = "Service",
        subtitle = "I'm Good at",
        path = "#service"
    ),
    Portfolio(
        id = "portfolio",
        title = "Portfolio",
        subtitle = "My Work",
        path = "#portfolio"
    ),
    Experience(
        id = "experience",
        title = "Experience",
        subtitle = "Work Experience",
        path = "#experience"
    ),*/
    Contact(
        id = "contact",
        title = "Contacto",
        subtitle = "Solicitar Demostración",
        path = "#contact"
    ),
    /*Testimonial(
        id = "testimonial",
        title = "Testimonial",
        subtitle = "Happy Customers",
        path = "#testimonial"
    ),
    Achievements(
        id = "achievements",
        title = "Achievements",
        subtitle = "Personal Achievements",
        path = "#achievements"
    )*/
}
package io.lomito.webpage.styles

import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.transform
import com.varabyte.kobweb.compose.ui.modifiers.transition
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.anyLink
import com.varabyte.kobweb.silk.components.style.hover
import io.lomito.webpage.models.Theme
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.*

val NavigationItemStyle by ComponentStyle {

    base {

        Modifier
            .color(Theme.Secondary.rgb)
            .transition(CSSTransition(property = "color", duration = 200.ms))
    }

    anyLink {

        Modifier.color(Theme.Secondary.rgb)
    }

    hover {

        Modifier.color(Theme.Primary.rgb)
    }
}

@OptIn(ExperimentalComposeWebApi::class)
val LogoStyle by ComponentStyle {

    base {

        Modifier
            .transform { scale(1) }
            .transition(CSSTransition(property = "transform", duration = 200.ms))
    }

    hover {

        Modifier.transform { scale((1.2)) }
    }
}

val SocialLinkStyle by ComponentStyle {

    base {

        Modifier
            .color(Theme.Gray.rgb)
            .transition(CSSTransition(property = "color", duration = 200.ms))
    }

    hover {

        Modifier.color(Theme.Primary.rgb)
    }
}

val MainButtonStyle by ComponentStyle {

    base {

        Modifier
            .width(120.px)
            .transition(CSSTransition(property = "width", duration = 200.ms))
    }

    hover {

        Modifier.width(140.px)
    }
}

@OptIn(ExperimentalComposeWebApi::class)
val MainImageStyle by ComponentStyle {

    base {

        Modifier
            .styleModifier { filter { grayscale(100.percent) } }
            .transition(CSSTransition(property = "filter", duration = 200.ms))
    }

    hover {

        Modifier.styleModifier { filter { grayscale(0.percent) } }
    }
}
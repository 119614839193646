package io.lomito.webpage.pages

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.core.Page
import io.lomito.webpage.components.BackToTopButton
import io.lomito.webpage.components.OverflowMenu
import io.lomito.webpage.sections.*

@Page
@Composable
fun HomePage() {

    var menuOpened by remember { mutableStateOf(false) }


    Box(
        modifier = Modifier.fillMaxSize()
    ) {

        Column(
            modifier = Modifier.fillMaxSize(),
            verticalArrangement = Arrangement.Top,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {

            MainSection(
                onMenuClicked = {

                    menuOpened = true
                }
            )

            AboutSection()

            // ServiceSection()

            // PortfolioSection()

            // AchievementsSection()

            // TestimonialSection()

            ContactSection()

            FooterSection()
        }

        BackToTopButton()

        if (menuOpened) {

            OverflowMenu(
                onMenuClosed = {

                    menuOpened = false
                }
            )
        }
    }
}

package io.lomito.webpage.sections

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.css.TextDecorationLine
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import io.lomito.webpage.components.Header
import io.lomito.webpage.components.SocialBar
import io.lomito.webpage.models.Section
import io.lomito.webpage.models.Theme
import io.lomito.webpage.styles.MainButtonStyle
import io.lomito.webpage.util.Constants.FONT_FAMILY
import io.lomito.webpage.util.Constants.SECTION_WIDTH
import io.lomito.webpage.util.Res.Image.BACKGROUND
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
fun MainSection(onMenuClicked: () -> Unit) {

    Box(
        modifier = Modifier
            .id(Section.Home.id)
            .maxWidth(SECTION_WIDTH.px),
        contentAlignment = Alignment.TopCenter
    ) {

        MainBackground()

        MainContent(onMenuClicked)
    }
}

@Composable
fun MainBackground() {

    Image(
        modifier = Modifier
            .fillMaxSize()
            .objectFit(ObjectFit.Cover),
        src = BACKGROUND,
        desc = "Main background",
    )
}

@Composable
fun MainContent(
    onMenuClicked: () -> Unit
) {

    val breakpoint = rememberBreakpoint()
    val simpleGridWidth = if (breakpoint > Breakpoint.MD) 80.percent else 90.percent

    Column(
        modifier = Modifier.fillMaxSize(),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.SpaceBetween
    ) {

        Header(
            onMenuClicked = onMenuClicked
        )

        Column(
            modifier = Modifier.fillMaxSize(),
            verticalArrangement = Arrangement.Bottom,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {

            SimpleGrid(
                modifier = Modifier.fillMaxWidth(simpleGridWidth),
                numColumns = numColumns(base = 1, md = 2)
            ) {

                MainText(breakpoint)

                MainImage()
            }
        }
    }
}

@Composable
fun MainText(breakpoint: Breakpoint) {

    val titleFontSize = if (breakpoint > Breakpoint.MD) 45.px else 32.px
    val subtitleFontSize = if (breakpoint > Breakpoint.MD) 68.px else 48.px

    Row {

        if (breakpoint > Breakpoint.MD) {

            SocialBar()
        }

        Column {

            P(
                attrs = Modifier
                    .margin(topBottom = 0.px)
                    .fontFamily(FONT_FAMILY)
                    .fontSize(titleFontSize)
                    .fontWeight(FontWeight.Normal)
                    .color(Theme.Primary.rgb)
                    .toAttrs()
            ) {

                Text("Amor Incondicional en Cuatro Patas")
            }

            P(
                attrs = Modifier
                    .margin(top = 20.px, bottom = 0.px)
                    .fontFamily(FONT_FAMILY)
                    .fontSize(subtitleFontSize)
                    .fontWeight(FontWeight.Bold)
                    .color(Theme.Secondary.rgb)
                    .toAttrs()
            ) {

                Text("Adopción de Lomitos")
            }

            P(
                attrs = Modifier
                    .margin(top = 10.px, bottom = 25.px)
                    .maxWidth(400.px)
                    .fontFamily(FONT_FAMILY)
                    .fontSize(20.px)
                    .fontWeight(FontWeight.Light)
                    .color(Theme.Secondary.rgb)
                    .toAttrs()
            ) {

                Text("¡Bienvenido a la Adopción de Lomitos, donde los lazos que creamos son eternos!")
            }

            Button(
                attrs = MainButtonStyle.toModifier()
                    .height(60.px)
                    .padding(leftRight = 20.px)
                    .border(0.px)
                    .borderRadius(r = 5.px)
                    .backgroundColor(Theme.Primary.rgb)
                    .color(Colors.White)
                    .cursor(Cursor.Pointer)
                    .toAttrs()
            ) {

                Link(
                    modifier = Modifier
                        .color(Colors.White)
                        .textDecorationLine(TextDecorationLine.None),
                    text = "Ver Lomitos",
                    path = Section.Contact.path
                )
            }
        }
    }
}

@Composable
fun MainImage() {

    Column(
        modifier = Modifier.fillMaxSize(80.percent).fillMaxHeight(),
        verticalArrangement = Arrangement.Center,
    ) {

        Image(
            modifier = Modifier.fillMaxWidth(),
            src = "home_main_image.png",
            desc = "Lomito main image"
        )
    }
}
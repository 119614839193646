package io.lomito.webpage.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.navigation.OpenLinkStrategy
import com.varabyte.kobweb.silk.components.icons.fa.FaFacebook
import com.varabyte.kobweb.silk.components.icons.fa.FaInstagram
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.style.toModifier
import io.lomito.webpage.styles.SocialLinkStyle
import org.jetbrains.compose.web.css.px

@Composable
fun SocialBar(
    row: Boolean = false
) {

    if (row) {

        Row(
            modifier = Modifier
                .margin(top = 25.px)
                .padding(leftRight = 25.px)
                .minHeight(40.px)
                .borderRadius(r = 20.px)
                .backgroundColor(Colors.White),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.Center
        ) {
            SocialLinks(row = true)
        }
    } else {

        Column(
            modifier = Modifier
                .margin(right = 25.px)
                .padding(topBottom = 25.px)
                .minWidth(40.px)
                .borderRadius(r = 20.px)
                .backgroundColor(Colors.White),
            verticalArrangement = Arrangement.Center,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {

            SocialLinks()
        }
    }
}

@Composable
private fun SocialLinks(
    row: Boolean = false
) {

    val iconMarginBottom = if (row) 0.px else 40.px
    val iconMarginRight = if (row) 40.px else 0.px

    Link(
        path = "https://www.facebook.com/lomito.io",
        openExternalLinksStrategy = OpenLinkStrategy.IN_NEW_TAB
    ){

        FaFacebook(
            modifier = SocialLinkStyle.toModifier().margin(bottom = iconMarginBottom, right = iconMarginRight),
            size = IconSize.LG
        )
    }

    Link(
        path = "https://www.instagram.com/lomito_io/",
        openExternalLinksStrategy = OpenLinkStrategy.IN_NEW_TAB
    ){

        FaInstagram(
            modifier = SocialLinkStyle.toModifier().margin(bottom = iconMarginBottom, right = iconMarginRight),
            size = IconSize.LG
        )
    }
}
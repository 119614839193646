package io.lomito.webpage.sections

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextDecorationLine
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import io.lomito.webpage.components.SocialBar
import io.lomito.webpage.models.Section
import io.lomito.webpage.models.Theme
import io.lomito.webpage.styles.NavigationItemStyle
import io.lomito.webpage.util.Constants.FONT_FAMILY
import io.lomito.webpage.util.Constants.SECTION_WIDTH
import io.lomito.webpage.util.Res
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun FooterSection() {

    Box(
        modifier = Modifier
            .fillMaxWidth()
            .maxWidth(SECTION_WIDTH.px)
            .padding(topBottom = 50.px)
            .backgroundColor(Theme.LighterGray.rgb),
        contentAlignment = Alignment.TopCenter
    ) {

        FooterContent()
    }
}

@Composable
fun FooterContent() {

    val breakpoint = rememberBreakpoint()

    Column(
        modifier = Modifier
            .fillMaxWidth(
                if (breakpoint >= Breakpoint.MD) 100.percent
                else 90.percent
            ),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {

        Image(
            modifier = Modifier.size(80.px).margin(bottom = 25.px),
            src = Res.Image.LOGO,
            desc = "Logo Image"
        )

        if (breakpoint > Breakpoint.SM) {

            Row(
                modifier = Modifier.fillMaxWidth().margin(bottom = 25.px),
                horizontalArrangement = Arrangement.Center
            ) {

                FooterMenu()
            }
        } else {

            Column(
                modifier = Modifier.fillMaxWidth().margin(bottom = 25.px),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {

                FooterMenu(row = false)
            }
        }

        SocialBar(row = true)
    }
}

@Composable
fun FooterMenu(row: Boolean = true) {

    Section.values().take(6).forEach { section ->

        Link(
            modifier = NavigationItemStyle.toModifier()
                .fontFamily(FONT_FAMILY)
                .padding(
                    right = if (row) 20.px else 0.px,
                    bottom = if (row) 0.px else 20.px
                )
                .fontSize(14.px)
                .fontWeight(FontWeight.Normal)
                .textDecorationLine(TextDecorationLine.None),
            path = section.path,
            text = section.title
        )
    }
}
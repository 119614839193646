package io.lomito.webpage.util

import androidx.compose.runtime.*
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.delay
import org.w3c.dom.events.EventListener

@Composable
fun ObserveViewportEntered(
    sectionId: String,
    distanceFromTop: Double,
    onViewportEntered: () -> Unit
) {

    var viewportEntered by remember { mutableStateOf(false) }

    val listener = remember {

        EventListener {

            val top = document.getElementById(sectionId)?.getBoundingClientRect()?.top

            if (top != null && top < distanceFromTop) {

                viewportEntered = true
            }
        }
    }

    LaunchedEffect(viewportEntered) {

        if (viewportEntered) {

            onViewportEntered()

            window.removeEventListener(type = "scroll", callback = listener)
        } else {

            window.addEventListener(type = "scroll", callback = listener)
        }
    }
}

suspend fun animateNumbers(
    number: Int,
    animationDelay: Long = 10L,
    onUpdate: (Int) -> Unit
) {

    (0..number).forEach {

        delay(animationDelay)
        onUpdate(it)
    }
}
package io.lomito.webpage.sections

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.FontStyle
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.navigation.OpenLinkStrategy
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaInstagram
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import io.lomito.webpage.components.SectionTitle
import io.lomito.webpage.components.SkillBar
import io.lomito.webpage.models.Section
import io.lomito.webpage.models.Skill
import io.lomito.webpage.models.Theme
import io.lomito.webpage.styles.SocialLinkStyle
import io.lomito.webpage.util.Constants
import io.lomito.webpage.util.Constants.FONT_FAMILY
import io.lomito.webpage.util.Constants.SECTION_WIDTH
import io.lomito.webpage.util.ObserveViewportEntered
import io.lomito.webpage.util.animateNumbers
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
fun AboutSection() {

    Box(
        modifier = Modifier
            .id(Section.About.id)
            .maxWidth(SECTION_WIDTH.px)
            .padding(topBottom = 150.px),
        contentAlignment = Alignment.TopCenter
    ) {

        AboutContent()
    }
}

@Composable
fun AboutContent() {

    val breakpoint = rememberBreakpoint()

    val contentWidth = if (breakpoint >= Breakpoint.MD) 100.percent else 90.percent

    Column(
        modifier = Modifier
            .fillMaxWidth(contentWidth)
            .maxWidth(1200.px),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {

        SimpleGrid(
            numColumns = numColumns(base = 1, md = 2)
        ){

            AboutImage(breakpoint)

            AboutMe(breakpoint)
        }
    }
}

@Composable
fun AboutImage(breakpoint: Breakpoint) {

    val imageMaxWidth = if (breakpoint >= Breakpoint.MD) 90.percent else 100.percent

    Box(
        modifier = Modifier.fillMaxWidth(),
        contentAlignment = Alignment.Center
    ) {

        Image(
            modifier = Modifier
                .fillMaxWidth(imageMaxWidth)
                .borderRadius(r = 20.px),
            src = "about_image.jpeg",
            desc = "About Image"
        )
    }
}

@Composable
fun AboutMe(breakpoint: Breakpoint) {

    val scope = rememberCoroutineScope()
    var viewportEntered by remember { mutableStateOf(false) }
    val animatedPercentage = remember { mutableStateListOf(0, 0, 0, 0, 0) }

    val imageMaxWidth = if (breakpoint >= Breakpoint.MD) 70.percent else 80.percent

    /*ObserveViewportEntered(
        sectionId = Section.About.id,
        distanceFromTop = 300.0,
        onViewportEntered = {

            viewportEntered = true

            Skill.values().forEach { skill ->

                scope.launch {

                    animateNumbers(
                        number = skill.percentage.value.toInt(),
                        onUpdate = {

                            animatedPercentage[skill.ordinal] = it
                        }
                    )
                }
            }
        }
    )*/

    Column(
        modifier = Modifier.fillMaxWidth(),
        verticalArrangement = Arrangement.Center
    ) {

        SectionTitle(
            section = Section.About
        )

        /*P(
            attrs = Modifier
                .maxWidth(500.px)
                .margin(topBottom = 25.px)
                .fontFamily(FONT_FAMILY)
                .fontSize(18.px)
                .fontWeight(FontWeight.Normal)
                .fontStyle(FontStyle.Italic)
                .color(Theme.Secondary.rgb)
                .toAttrs()
        ) {

            Text("¡Descarga nuestra app!")
        }

        Skill.values().forEach { skill ->

            SkillBar(
                name = skill.title,
                index = skill.ordinal,
                percentage = if (viewportEntered) skill.percentage else 0.percent,
                animatedPercentage = if (viewportEntered) animatedPercentage[skill.ordinal] else 0
            )
        }*/

        Link(
            path = "https://play.google.com/store/apps/details?id=dev.leonardom.lomito",
            openExternalLinksStrategy = OpenLinkStrategy.IN_NEW_TAB
        ){

            Image(
                modifier = Modifier.fillMaxWidth(imageMaxWidth),
                src = "google-play-badge.png",
                desc = "Download app image"
            )
        }
    }
}